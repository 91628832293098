<template>
  <div>
    <b-notification
        v-model="isError"
        type="is-danger"
        aria-close-label="Close notification"
        :closable="false">
        <b>Error fetching meal plan!</b><br />Try refreshing the page. If the error persists check your network connection or let BM know.
    </b-notification>
    <b-loading :is-full-page="true" v-model="isLoading"></b-loading>
    <div v-if="!isLoading && !isError" class="content m-5">
        <Keypress key-event="keyup" :modifiers="['ctrlKey']" :key-code="83" :preventDefault="true" @success="save"  />
        <div class="columns">
          <div class="column">
            <h1 class="title is-1">{{ title }}</h1>
          </div>
          <div class="column is-narrow">
            <b-field horizontal>
                <b-select v-model="mealplan.status" placeholder="Status" @input="save">
                    <option>draft</option>
                    <option>test</option>
                    <option>fix</option>
                    <option>live</option>
                  </b-select>
             </b-field>
         </div>
          <div class="column is-narrow">
            <b-button outlined type="is-primary" class="right" icon-left="check" @click="saveAndClose">Save & Close</b-button>
          </div>
          <div class="column is-narrow">
            <b-button type="is-primary" class="right" icon-left="content-save" @click="save">Save</b-button>
          </div>
        </div>
        <div class="columns">
            <section id="general" class="column">

            <h2 class="subtitle is-3">General</h2>
            <b-field horizontal label="Name">
              <b-input v-model="mealplan.title" />
             </b-field>
            <b-field horizontal label="Description">
             <editor v-model="mealplan.description" @input="saveDebounced"/>
            </b-field>
            <b-field horizontal label="Date">
             <b-datepicker
              v-model="date"
              trap-focus
              placeholder="Click to select..."
              :unselectable-days-of-week="[0,1,2,3,5,6]"
              icon="calendar-today"
              :icon-right="mealplan.date ? 'close-circle' : ''"
              icon-right-clickable
              @icon-right-click="clearDate"
              @input="save" />
            </b-field>
            <b-field horizontal label="Colour">
              <b-select placeholder="Choose colour" icon="format-color-fill"
                v-model="mealplan.backgroundColour"
                @input="saveDebounced">
                <option
                      v-for="option in colours"
                      :value="option"
                      :key="option.name">
                      {{ option.name }}
                  </option>
                </b-select>
            </b-field>
            <b-field horizontal label="Highlight" >
              <b-select placeholder="Choose colour" icon="palette"
                v-model="mealplan.highlightColour"
                @input="saveDebounced">
                <option
                      v-for="option in colours"
                      :value="option"
                      :key="option.name">
                      {{ option.name }}
                  </option>
                </b-select>
            </b-field>
            <b-field horizontal label="Colour Preview" >
              <div class="card rounded-border" :style="backgroundStyle">
                <div class="px-5 pt-5">
                  <figure class="m-0 rounded-border">
                    <img :src="image" />
                  </figure>
                </div>
                <div class="px-5 py-3">
                  <h2 :style="backgroundStyle" class="mb-1" >{{ mealplan.title }}</h2>
                  <p :style="backgroundStyle" >{{ this.mealplan.date_string }}</p>
                </div>
              </div>
              <div :style="highlightBackgroundStyle" class=" card pt-5 rounded-border">
                <h1 class="mb-1" >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</h1>
              </div>
            </b-field>
           </section>
           <section id="images" class="column is-one-third">
             <EditImages
               v-model="mealplan.images"
               @input="save"
             />
           </section>
         </div>
         <section id="recipes" class="mt-6">
           <EditRecipes
             title="🍽 This week's recipes"
             :maxRecipes="4"
             :allowMultipleFreeRecipes="false"
             v-model="mealplan.recipes"
             @input="save"
             @recipe-added="recipeAdded($event)"
             @recipe-removed="recipeRemoved($event)"
            />
         </section>
         <section id="tips" class="mt-6">
           <EditTips
             v-model="mealplan.tips"
             title="💡 This week's top tips"
             @input="save"
           />
         </section>
         <section id="tips" class="mt-6">
           <div class="columns">
             <div class="column">
               <h2 class="subtitle is-3">🛒 Shopping List</h2>
             </div>
             <div class="column is-narrow">
               <b-button type="is-primary" class="right" icon-left="refresh" @click="refreshShoppingList">Refresh</b-button>
             </div>
           </div>
           <IngredientList :ingredients="displayShoppingList" />
         </section>
        <section id="notes" class="mb-2 mt-6">
          <h2 class="subtitle is-3">Notes (internal only)</h2>
          <editor v-model="mealplan.notes" @input="saveDebounced"/>
        </section>
      <br />
      <section class="card" id="preview" label="Preview">
        <div class="p-6" :style="backgroundStyle">
          <h2 :style="backgroundStyle" class="title is-2">{{ mealplan.title }}</h2>
          <p :style="backgroundStyle" class="subtitle">{{ mealplan.date_string }}</p>
          <p :style="backgroundStyle" v-html="mealplan.description"  />
          <br />
        </div>
        <div class="mx-6 p-5 card mt-minus rounded-border" :style="highlightBackgroundStyle">
          <h3 :style="highlightBodyStyle" class="title is-3">Social Post output</h3>
          <p :style="highlightBodyStyle" v-html="mealplan.description"  />
          <p :style="highlightBodyStyle">THIS WEEK'S RECIPES</p>
          <div v-bind:key="recipe.id" v-for="(recipe, index) in displayRecipes">
            <router-link :style="highlightBodyStyle" v-if="recipe.id" :to="{ name: 'RecipeView', params: { id: recipe.id }}">{{ index+1 }}. {{ recipe.title }}</router-link>
          </div>
          <p :style="highlightBodyStyle">Follow @feeds.mealplans for amazing recipes</p>
        </div>
        <div class="p-6">
          <h3 class="title is-3" >This week's top tips</h3>
          <div v-bind:key="tip.id" v-for="tip in mealplan.tips">
            <p v-html="tip.value" />
          </div>
      </div>
      </section>
    </div>
  </div>
</template>

<script>
import debounce from 'lodash/debounce'
import sortBy from 'lodash/sortBy'
import cloneDeep from 'lodash/cloneDeep'
import EditTips from "@/components/EditTips"
import EditImages from "@/components/EditImages"
import EditRecipes from "@/components/EditRecipes"
import Editor from "@/components/BaseEditor"
import db from "@/firebase/db"
import firebase from 'firebase/app'
import Colours from "@/models/Colours"
import DateFormatter from "@/formatters/DateFormatter"
import IngredientList from "@/components/IngredientList"

export default {
  name: 'MealPlanView',
  components: {
    EditImages,
    EditTips,
    EditRecipes,
    Editor,
    Keypress: () => import('vue-keypress'),
    IngredientList,
  },
  props: [
    'id'
  ],
  data() {
    return {
      isLoading: true,
      isError: false,
      mealplan: {}
    };
  },
  computed: {
    title() {
      if (this.mealplan.title == null) {
        return null;
      }
      return this.mealplan.title ?? "New Meal Plan"
    },
    image() {
      if (this.mealplan.images == null) {
        return null;
      }
      return this.mealplan.images.length > 0 ? this.mealplan.images[0].src : null
    },
    date: {
      get() {
        if (this.mealplan.date == null) {
          return null;
        }
        return new Date(this.mealplan.date)
      },
      set(val) {
        this.mealplan.date = val.getTime();
        this.mealplan.date_string = this.formatDate(this.mealplan.date);
      }
    },
    colours() {
      return Colours.all;
    },
    backgroundStyle() {
      if (this.mealplan.backgroundColour == null) {
        return { backgroundColor: Colours.white.colour }
      }
      return {
        backgroundColor: this.mealplan.backgroundColour.colour,
        color: (this.mealplan.backgroundColour.invertTheme == "dark") ? Colours.black.colour : Colours.white.colour
      }
    },
    highlightBackgroundStyle() {
      if (this.mealplan.highlightColour == null) {
        return { backgroundColor: Colours.black.colour }
      }
      return { backgroundColor: this.mealplan.highlightColour.colour }
    },
    highlightBodyStyle() {
      if (this.mealplan.highlightColour == null) {
        return { color: Colours.white.colour }
      }
      return { color: (this.mealplan.highlightColour.invertTheme == "dark") ? Colours.black.colour : Colours.white.colour }
    },
    displayShoppingList() {
      if (this.mealplan.shopping_list == null) return [];

      // deep clone our list for manipulation
      let displayShoppingList = cloneDeep(this.mealplan.shopping_list);
      displayShoppingList = displayShoppingList.reduce(function(accumulator, current) {

        // we remove the prep for everything
        current.prep = "";

        let found = accumulator.find(function(item) {
          // same ingredient name is a match
          return item.name == current.name;
        });

        function convertToMls(found, current){
          if (found.unit == "tbsp") { found.amount = parseFloat(found.amount)*15 }
          if (current.unit == "tbsp"){ current.amount = parseFloat(current.amount)*15 }
          if (found.unit == "tsp") { found.amount = parseFloat(found.amount)*5 }
          if (current.unit == "tsp"){ current.amount = parseFloat(current.amount)*5 }

          found.unit = "ml";
          current.unit = "ml";
        }

        function convertToTbsp(found, current){
          // convert tsp to tbsp
          // 3 tsp == 1 tbsp
          if (found.unit == "tsp") { found.amount = parseFloat(found.amount)/3 }
          if (current.unit == "tsp"){ current.amount = parseFloat(current.amount)/3 }
          found.unit = "tbsp";
          current.unit = "tbsp";
        }

        function mergeIngredients(found, current){
          // sum up totals
          found.amount = parseFloat(found.amount); // make sure we sum numbers
          found.amount += +current.amount;

          // combine the tips
          found.tips = found.tips.concat(current.tips);
        }

        if (!found){
          // it's something new! we add it to the list
          accumulator.push(current);
        }else {
          // we have a
          if (found.unit == current.unit) {
            // we already have the same unit
            mergeIngredients(found, current);
          }else {
            // convert supported units before summing up later!
            switch (`${found.unit}-${current.unit}`){
              case ("tsp-tbsp"):
              case ("tbsp-tsp"):
                convertToTbsp(found, current);
                mergeIngredients(found, current);
                break;
              case ("tsp-ml"):
              case ("ml-tsp"):
              case ("tbsp-ml"):
              case ("ml-tbsp"):
                convertToMls(found, current);
                mergeIngredients(found, current);
                break;
              default:
                // we don't support conversion so we add new item!
                accumulator.push(current);
            }
          }
        }

        return accumulator;
      }, []);

      return sortBy(displayShoppingList, 'name');
    },
    displayRecipes() {
      var recipes = this.mealplan.recipes ?? {};

      // flatten the recipes map from firebase to an array
      // e.g. from {id: {title: bar}, ...} to [{id: foo, title: bar}, ...]
      var array = Object.keys(recipes).map(function(recipeID) {
        // create new object with the recipeID included
        var newObj = {id: recipeID};
        // add all the nested properties
        for (var property in recipes[recipeID]) {
          newObj[property] = recipes[recipeID][property];
        }
        return newObj;
      });

      // set our local array sorted by the recipe object's position to bgin with
      return array.sort((a, b) => a.position - b.position);

    }
  },
  created: function () {
    // fetch our meal_plan
    db.collection('meal_plans')
      .doc(this.id)
      .get()
      .then(snapshot => {
        if (snapshot.exists) {
          this.mealplan = snapshot.data();
        }else {
          this.isError = true; // show our error
        }
        this.isLoading = false; // remove our loader
      })
      .catch((error) => {
        this.isError = true;
        this.$buefy.notification.open({type: 'is-danger', message: `Error fetching Meal plan: ${error}`})
      });
  },
  methods: {
    clearDate () {
      this.mealplan.date = null
    },
    formatDate(dateTime) {
      return DateFormatter.displayDate(dateTime, false);
    },
    saveDebounced: debounce(function () {
        this.save()
      }, 1000),
    async saveAndClose(){
      this.save()
        .then(() => {
          this.$router.go(-1)
        })
    },
    async save(){
      db.collection('meal_plans')
        .doc(this.id)
        .set(this.mealplan)
        .then(() => {
          this.$buefy.toast.open({type: 'is-success', message: `Meal plan successfully updated!`})
        })
        .catch((error) => {
          this.$buefy.toast.open({type: 'is-danger', message: `Error saving Meal plan: ${error}`})
        });
    },
    async recipeAdded(recipe){
      // console.log(`Mealplan ${this.id} added recipe: ${recipe.id}`);
      // add entry in recipeMealplans
      //

      this.addRecipeIngredients(recipe);

      const entry = {};
      entry[`${this.id}`] = true; // TODO: store name & date and any other useful info here
      db.collection("recipe_mealplans").doc(recipe.id)
        .set(entry, {merge: true})
        .then(() => {
            // console.log("recipe_mealplans entry added!");
        })
        .catch((error) => {
            console.error("Error adding recipe_mealplans entry: ", error);
        });
    },
    async recipeRemoved(recipe){
      // console.log(`Mealplan ${this.id} removed recipe: ${recipe.id}`);
      // remove entry from recipeMealplans

      this.removeRecipeIngredients(recipe);

      // use the delete sentinel to remove a field.
      const remove = {};
      remove[`${this.id}`] = firebase.firestore.FieldValue.delete();
      db.collection("recipe_mealplans").doc(recipe.id)
        .update(remove)
        .then(() => {
            // console.log("recipe_mealplans entry removed!");
        })
        .catch((error) => {
            console.error("Error removing recipe_mealplans entry: ", error);
        });
    },
    async addRecipeIngredients(recipe) {

      db.collection('recipes')
        .doc(recipe.id)
        .get()
        .then(snapshot => {
          var ingredients = snapshot.data().ingredients;

          if (this.mealplan.shopping_list == null) {
            this.mealplan.shopping_list = [];
          }

          // add the recipe's ID to each shopping list item
          ingredients.forEach((item) => {
            item.recipe_id = recipe.id;
          });

          this.mealplan.shopping_list = this.mealplan.shopping_list.concat(ingredients);

          this.saveDebounced();
        })
    },
    async removeRecipeIngredients(recipe) {
      this.mealplan.shopping_list = this.mealplan.shopping_list.filter(item => item.recipe_id != recipe.id);
      this.saveDebounced();
    },
    async refreshShoppingList() {
      this.mealplan.shopping_list = [];

      Object.values(this.mealplan.recipes).forEach((recipe) => {
        this.addRecipeIngredients(recipe);
      });
    }
  }
}


</script>

<style>
#preview {
  background-color: #F7F1EA;
}
.mt-minus {
  margin-top: -4rem !important;
}
.rounded-border{
    border-radius:20px;
}
</style>
