<template>
  <div>
    <h2 class="subtitle is-3">{{ title }}</h2>
    <draggable
      v-model="tips"
      draggable=".tip"
      handle=".drag-handle"
      ghostClass="drag-ghost"
      dragClass="drag-item">
      <EditTip
        v-for="(tip, index) in tips"
        :key="tip.id"
        :tipIn="tip"
        :isNewTip="false"
        @remove-tip="removeTip($event, index)"
        @update-tip="updateTip($event, index)"
        />
    </draggable>
    <EditTip
      :isNewTip="true"
      @add-tip="addTip($event)"
      />
  </div>
</template>

<script>
import EditTip from "@/components/EditTip"
import Draggable from "vuedraggable"

export default {
  name: 'EditTips',
  components: {
    EditTip,
    Draggable
  },
  props: {
    value: Array,
    title: String
  },
  computed: {
    tips: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    addTip(newTip) {
      this.tips.push(newTip);
      this.$emit('input', this.tips)
    },
    removeTip(tip, index) {
      this.$buefy.dialog.confirm({
          title: 'Deleting Tip',
          message: `Are you sure you want to delete this tip?<br /><br /><strong>This action cannot be undone!</strong>`,
          confirmText: 'Delete Tip',
          type: 'is-danger',
          hasIcon: true,
          onConfirm: () => {
            this.$delete(this.tips, index);
            this.$emit('input', this.tips);
          }
      });
    },
    updateTip(){
      this.$emit('input', this.tips)
    }
  }
}
</script>
