var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"recipe my-0 py-1"},[_c('b-field',{attrs:{"grouped":""}},[(!_vm.isNew)?_c('b-icon',{staticClass:"drag-handle pt-4 pr-1",attrs:{"icon":"drag"}}):_vm._e(),(_vm.isNew)?_c('b-icon',{staticClass:"pt-4 pr-1",attrs:{"icon":"plus"}}):_vm._e(),(!_vm.isNew)?_c('figure',{staticClass:"m-0 mx-2 p-0 image is-48x48"},[_c('img',{staticClass:"thumbnail",attrs:{"src":_vm.thumbnailSrc}})]):_vm._e(),_c('b-autocomplete',{staticClass:"is-flex-grow-1 mr-1",attrs:{"custom-class":"has-text-weight-bold","field":"title","disabled":!_vm.isNew,"data":_vm.suggestions,"loading":_vm.isLoading,"placeholder":"Select a recipe...","open-on-focus":"","keep-first":""},on:{"typing":_vm.getAsyncData,"select":option => _vm.selected(option)},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('div',{staticClass:"media p-2"},[_c('div',{staticClass:"media-left"},[_c('img',{attrs:{"width":"48","src":`${_vm.optionThumbnailSrc(props.option)}`}})]),_c('div',{staticClass:"media-content"},[(props.option.is_variant)?_c('h1',{staticClass:"is-size-5 has-text-weight-bold pb-0 mb-0"},[_vm._v(" "+_vm._s(props.option.title)+" "),_c('span',{staticClass:"has-text-success"},[_vm._v("("+_vm._s(props.option.name)+")")])]):_c('h1',{staticClass:"is-size-5 has-text-weight-bold pb-0 mb-0"},[_vm._v(_vm._s(props.option.title))]),_c('p',{staticClass:"is-size-8 mt-1"},[_c('span',{class:[
                                        'tag',
                                        {'is-danger is-light': props.option.status == 'draft'},
                                        {'is-warning': props.option.status == 'test'},
                                        {'is-info': props.option.status == 'fix'},
                                        {'is-success': props.option.status == 'live'}
                                      ]},[_c('b',[_vm._v(_vm._s(props.option.status))])]),_c('b-tag',{staticClass:"mx-1",attrs:{"type":props.option.is_free ? 'is-success' : 'is-white'}},[_c('b-icon',{attrs:{"size":"is-small","type":props.option.is_free ? 'is-white' : 'is-default',"icon":"star"}})],1),_vm._v(" Serves: "),_c('b',[_vm._v(_vm._s(props.option.servings))]),_vm._v(", Ingredients: "),_c('b',[_vm._v(_vm._s(props.option.ingredients.length))]),_vm._v(", Prep: "),_c('b',[_vm._v(_vm._s(props.option.prepTime))]),_vm._v(", Cook: "),_c('b',[_vm._v(_vm._s(props.option.cookTime))])],1)])])]}}]),model:{value:(_vm.recipe.title),callback:function ($$v) {_vm.$set(_vm.recipe, "title", $$v)},expression:"recipe.title"}}),(!_vm.isNew && _vm.shouldDisplayName())?_c('b-field',{staticClass:"is-flex-shrink-5",attrs:{"label":"Variant name","label-position":"on-border","type":"is-primary"}},[_c('b-input',{attrs:{"disabled":"","type":"is-success","custom-class":"has-text-weight-bold"},model:{value:(_vm.recipe.name),callback:function ($$v) {_vm.$set(_vm.recipe, "name", $$v)},expression:"recipe.name"}})],1):_vm._e(),(_vm.recipe.status != null)?_c('span',{class:[
                        'tag',
                        'is-large',
                        'mr-1',
                        {'is-danger is-light ': _vm.recipe.status == 'draft'},
                        {'is-warning': _vm.recipe.status == 'test'},
                        {'is-info': _vm.recipe.status == 'fix'},
                        {'is-success': _vm.recipe.status == 'live'}
                      ]},[_c('b',[_vm._v(_vm._s(_vm.recipe.status))])]):_vm._e(),_c('b-tooltip',{attrs:{"label":this.recipe.is_free ? 'Free to non-members' : 'Member recipe',"type":"is-success","position":"is-top","size":"is-large"}},[(!_vm.isNew)?_c('b-tag',{staticClass:"mr-1",attrs:{"size":"is-large","type":this.recipe.is_free ? 'is-success' : 'is-default'}},[_c('b-icon',{attrs:{"size":"is-small","type":this.recipe.is_free ? 'is-white' : 'is-default',"icon":"star"}})],1):_vm._e()],1),_c('p',{staticClass:"control"},[(!_vm.isNew)?_c('b-button',{staticClass:"mr-2",attrs:{"icon-right":"arrow-right"},on:{"click":_vm.viewRecipe}},[_vm._v("View recipe")]):_vm._e(),(_vm.isNew)?_c('b-button',{attrs:{"disabled":!_vm.isValidRecipe,"type":"is-primary","icon-right":"plus"},on:{"click":_vm.add}}):_c('b-button',{attrs:{"icon-right":"delete"},on:{"click":_vm.remove}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }