<template>
  <div class="modal-card" style="width: 460px">
    <header class="modal-card-head">
        <p class="modal-card-title">Select and crop an image</p>
        <button
            type="button"
            class="delete"
            @click="$emit('close')" />
    </header>
    <section class="modal-card-body">
      <croppa v-model="cropper"
          :width="420"
          :height="420"
          :canvas-color="'transparent'"
          :placeholder="'Drop an image here or click to upload'"
          :placeholder-font-size="20"
          :placeholder-color="'default'"
          :accept="'image/*'"
          :file-size-limit="0"
          :quality="2"
          :zoom-speed="3"
          :prevent-white-space="true"
          :show-remove-button="false">
        </croppa>
    </section>
    <footer class="modal-card-foot">
        <b-button
            label="Remove"
            @click="resetImage" />
        <b-button
            label="Save"
            type="is-primary"
            :disabled="isSaveDisabled"
            @click="useImage"/>
    </footer>
  </div>
</template>

<script>
import croppa from 'vue-croppa';

export default {
  name: 'SelectImageModal',
  components: {
    croppa: croppa.component
  },
  data() {
    return {
      cropper: {}
    }
  },
  computed: {
    isSaveDisabled() {
      return !this.cropper.imageSet
    }
  },
  methods: {
    resetImage() {
      this.cropper.remove()
    },
    useImage() {
      this.cropper.generateBlob(
        blob => {
          this.$emit('image-selected', blob);
          this.$emit('close');
        },
        'image/jpeg',
        0.9
      );
    }
  }
}
</script>

<style scoped>

.croppa-container{
  font-size: 0 !important;
   background-color: #dfdfdf;
 }
.croppa-container:hover{
  cursor: pointer;
   background-color: #ececec;
 }
</style>
