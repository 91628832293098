<template>
  <div class="tip my-1">
    <b-field grouped >
        <b-icon
            v-if="!isNewTip"
            class="drag-handle pt-5 pr-1"
            icon="drag"
            size="is-small">
        </b-icon>
        <b-icon
            v-if="isNewTip"
            class="pt-5 pr-1"
            icon="plus"
            size="is-small">
        </b-icon>
        <editor
          class="is-flex-grow-1 mx-1"
          v-model.trim="tip.value"
          :placeholder='"e.g. leave the onions longer if you like them burnt"'
          @input="updateTip"
          />
        <p class="control">
          <b-button v-if="isNewTip" :class="{ 'is-small': isSmall}" type="is-primary" icon-right="plus" @click="addTip" />
          <b-button v-else :class="{'is-small': isSmall}" icon-right="delete" @click="removeTip" />
        </p>
    </b-field>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid'
import Editor from "@/components/BaseEditor"
import debounce from "lodash/debounce"

export default {
  name: 'EditTip',
  components: {
    Editor,
  },
  props: [
    "tipIn",
    "isNewTip",
    "isSmall"
  ],
  data() {
    return {
      tip: null,
    }
  },
  created() {
    this.tip = this.tipIn ?? { id: null, value: null }
  },
  computed: {
  },
  methods: {
    addTip() {
      this.tip.id = uuidv4();
      this.$emit('add-tip', this.tip);
      this.tip = {id: null, value: null};
    },
    removeTip() {
      this.$emit('remove-tip', this.tip);
    },
    updateTip: debounce(function () {
      this.$emit('update-tip', this.tip);
    }, 1000)
  }
}
</script>
