<template>
  <div>
      <b-tooltip
        v-if="ingredient.recipe_id"
        type="is-light"
        position="is-bottom"
        size="is-large">
      <p @click="viewRecipe"><i>{{optionalString}}</i>{{ amountString }} <b>{{ ingredientString }}</b> {{prepString}}</p>
      <template v-slot:content>
        <b>Click to view recipe: {{ ingredient.recipe_id }}</b>
      </template>
    </b-tooltip>
      <p v-if="!ingredient.recipe_id"><i>{{optionalString}}</i>{{ amountString }} <b>{{ ingredientString }}</b> {{prepString}}</p>
      <InlineTips v-if="showTips" :tips="ingredient.tips" />
  </div>
</template>

<script>
import InlineTips from './InlineTips';

export default {
  name: 'IngredientItem',
  components: {
    InlineTips
  },
  props: {
    ingredient: {},
    showTips: {
      default: true
    },
    ratio: {
      default: 1.0
    }
  },
  computed: {
    optionalString() {
      return this.ingredient.optional ? "(Optional) " : ""
    },
    amount() {
      return parseFloat((this.ingredient.amount * this.ratio).toFixed(4)) ?? 1.0
    },
    amountString() {
      return "" + this.amount + " " + this.ingredient.unit
    },
    ingredientString() {
      return this.ingredient.name?.toLowerCase() ?? ""
    },
    hasPrep() {
      if (typeof this.ingredient.prep !== 'undefined') {
        if (this.ingredient.prep == ""){
          return false
        }else {
          return true
        }
      }
      return false
    },
    prepString() {
      return this.ingredient.prep ? `(${this.ingredient.prep})` : ""
    }
  },
  methods: {
    viewRecipe() {
      if (this.ingredient.recipe_id != null){
        this.$router.push({ name: 'RecipeView', params: { id: this.ingredient.recipe_id } })
      }
    }
  }
}
</script>

<style scoped>
  .completed {
    text-decoration: line-through;
  }
</style>
