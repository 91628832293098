export default class Colours {
    static white = {
      name: 'White',
      colour:'#FFFFFF',
      invertTheme: 'dark'
    };
    static offWhite = {
      name: 'Off White',
      colour:'#FEF7ED',
      invertTheme: 'dark'
    };
    static black = {
      name: 'Black',
      colour:'#555555',
      invertTheme: 'light'
    };
    static darkGreen =  {
      name: 'Dark Green',
      colour: '#006f6a',
      invertTheme: 'light'
    };
    static burntOrange = {
      name: 'Burnt Orange',
      colour: '#f07f51',
      invertTheme: 'light'
    };
    static pink = {
      name: 'Pink',
      colour: '#f4d6de',
      invertTheme: 'dark'
    };
    static lilac = {
      name: 'Lilac',
      colour: '#d2b5d7',
      invertTheme: 'dark'
    };
    static yellow = {
      name: 'Yellow',
      colour: '#ffcb49',
      invertTheme: 'dark'
    };
    static purple = {
      name: 'Purple',
      colour: '#7f7dbc',
      invertTheme: 'light'
    };
    static blue = {
      name: 'Blue',
      colour: '#88d0e4',
      invertTheme: 'dark'
    };
    static orange = {
      name: 'Orange',
      colour: '#f8951d',
      invertTheme: 'light'
    };
    static red = {
      name: 'Red',
      colour: '#f15e65',
      invertTheme: 'light'
    };
    static green = {
      name: 'Green',
      colour: '#a3d8c5',
      invertTheme: 'dark'
    };
    static lemon = {
      name: 'Lemon',
      colour: '#faef54',
      invertTheme: 'dark'
    };
    static darkGreenPastel =  {
      name: 'Dark Green Pastel',
      colour: '#a3d8c5',
      invertTheme: 'dark'
    };
    static burntOrangePastel = {
      name: 'Burnt Orange Pastel',
      colour: '#F8D5B6',
      invertTheme: 'dark'
    };
    static pinkPastel = {
      name: 'Pink Pastel',
      colour: '#f4d6de',
      invertTheme: 'dark'
    };
    static lilacPastel = {
      name: 'Lilac Pastel',
      colour: '#E4D4E0',
      invertTheme: 'dark'
    };
    static yellowPastel = {
      name: 'Yellow Pastel',
      colour: '#FADE9D',
      invertTheme: 'dark'
    };
    static all = [this.white, this.offWhite, this.black, this.darkGreen, this.burntOrange,
                  this.pink, this.lilac, this.yellow, this.purple, this.blue,
                  this.orange, this.red, this.green, this.lemon, this.darkGreenPastel,
                  this.burntOrangePastel, this.pinkPastel, this.lilacPastel, this.yellowPastel];
}
