<template>
  <div>
      <ul>
        <li v-bind:key="tip.id" v-for="tip in tips">
          <InlineTip v-bind:tip="tip" />
        </li>
      </ul>
  </div>
</template>

<script>
import InlineTip from './InlineTip';

export default {
  name: 'InlineTips',
  components: {
    InlineTip
  },
  props: [
    "tips"
  ]
}
</script>

<style scoped>

</style>
