<template>
  <div>
    <p v-html="tip.value"></p>
  </div>
</template>

<script>

export default {
  name: 'InlineTip',
  props: [
    "tip"
  ]
}
</script>

<style scoped>
  p {
    font-style: italic;
  }
</style>
