<template>
  <div>
    <h2 class="subtitle is-3">Images</h2>
    <draggable
      v-model="images"
      draggable=".image"
      ghostClass="drag-ghost"
      dragClass="drag-item">
      <EditImage
        v-for="(image, index) in images"
        :key="image.id"
        :value="image"
        @remove-image="removeImage($event, index)"
        />
    </draggable>
    <EditImage
      isNew
      @add-image="addImage($event)"
      />
  </div>
</template>

<script>
import EditImage from "@/components/EditImage.vue"
import Draggable from 'vuedraggable'

export default {
  name: 'EditImages',
  components: {
    EditImage,
    Draggable
  },
  props: {
    value: {
      type: Array,
      default: function () { return [] }
    }
  },
  computed: {
    images: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    addImage(newImage) {
      console.log("add: " + newImage);
      this.images.push(newImage);
      this.$emit('input', this.images)
    },
    removeImage(image, index) {
      this.$delete(this.images, index);
      this.$emit('input', this.images)
    }
  }
}
</script>

<style scoped>
</style>
