<template>
  <div>
    <div class="columns">
     <div class="column">
       <h2 class="subtitle is-3">{{titleDisplay}}</h2>
     </div>
     <div class="column is-narrow">
       <b-button type="is-primary" class="right" icon-left="refresh" @click="refreshRecipeList">Refresh</b-button>
     </div>
   </div>
    <draggable
      v-model="recipesArray"
      draggable=".recipe"
      handle=".drag-handle"
      ghostClass="drag-ghost"
      dragClass="drag-item"
      @change="update($event)">
      <EditRecipe
        v-for="(recipe, index) in recipesArray"
        :key="recipe.id"
        :recipeIn="recipe"
        @remove-recipe="remove(index)"
        @make-recipe-free="makeRecipeFree(index)" />
    </draggable>
    <EditRecipe
      v-if="isAddEnabled"
      isNew
      @add-recipe="add($event)" />
  </div>
</template>

<script>
import EditRecipe from "@/components/EditRecipe"
import Draggable from "vuedraggable"
import cloneDeep from 'lodash/cloneDeep'
import db from "@/firebase/db"

export default {
  name: 'EditRecipes',
  components: {
    EditRecipe,
    Draggable
  },
  props: {
    value: Object,
    title: String,
    maxRecipes: Number,
    allowMultipleFreeRecipes: Boolean
  },
  data() {
    return {
      // local manipulation of the recipes list is performed on the array for draggable
      recipesArray: null,
    }
  },
  created() {
    var recipes = this.value ?? {};

    // flatten the recipes map from firebase to an array
    // e.g. from {id: {title: bar}, ...} to [{id: foo, title: bar}, ...]
    var array = Object.keys(recipes).map(function(recipeID) {
      // create new object with the recipeID included
      var newObj = {id: recipeID};
      // add all the nested properties
      for (var property in recipes[recipeID]) {
        newObj[property] = recipes[recipeID][property];
      }
      return newObj;
    });

    // set our local array sorted by the recipe object's position to bgin with
    this.recipesArray = array.sort((a, b) => a.position - b.position);

  },
  computed: {
    titleDisplay() {
      return (this.title != null) ? this.title : "🍽 Recipes";
    },
    // use to return an object expected by our data structure
    recipesObject: {
      get() {
        // convert array to object
        let position = 0;
        return this.recipesArray.reduce((accumulator, recipe) => {
          recipe.position = position;
          accumulator[recipe.id] = recipe;
          position++;
          return accumulator;
        } ,{});
      }
    },
    isAddEnabled() {
      if (this.recipesArray == null) {
        return false;
      }
      return this.recipesArray.length < this.maxRecipes;
    }
  },
  methods: {
    add(newRecipe) {
      // check for duplicate before adding
      if (this.isDuplicate(newRecipe)){
        this.$buefy.toast.open({type: 'is-warning', message: `Recipe is already part of the collection!`})
      }else {
        this.recipesArray.push(newRecipe);
        this.resetPosition(); // indexes might have changed
        this.$emit('recipe-added', newRecipe);
        this.$emit('input', this.recipesObject);
      }
    },
    remove(index) {
      const removedRecipe = this.recipesArray[index];
      this.$delete(this.recipesArray, index);
      this.resetPosition(); // indexes might have changed
      this.$emit('recipe-removed', removedRecipe);
      this.$emit('input', this.recipesObject);
    },
    makeRecipeFree(index) {
      const recipe = this.recipesArray[index];
      
      if (!this.allowMultipleFreeRecipes){
        // reset our free value
        this.recipesArray.forEach((recipe) => {
          recipe.is_free = false;
        });  
      }
      
      recipe.is_free = !recipe.is_free;
      this.$emit('input', this.recipesObject);
    },
    update(){
      this.resetPosition(); // indexes have changed
      this.$emit('input', this.recipesObject);
    },
    resetPosition(){
      // store the local index as the objects position in firebase
      for (let i = 0; i<this.recipesArray.length; i++) {
        this.recipesArray[i].position = i;
      }
    },
    isDuplicate(newRecipe) {
      return this.recipesArray.map(i => { return i.id }).includes(newRecipe.id)
    },
    async refreshRecipeList() {
      // keep a copy of our recipes
      let recipes = cloneDeep(this.recipesArray);

      // remove all recipes
      while(this.recipesArray.length > 0){
        this.remove(this.recipesArray.length-1);
      }

      // add them back in
      // TODO this is not DRY since the EditRecipe module does something similar when getting set
      for (let i = 0; i<recipes.length; i++) {
        let r = recipes[i];

        db.collection('recipes')
        .doc(r.id)
        .get()
        .then(snapshot => {
          if (snapshot.exists) {

            let recipe = {};
            let fullRecipe = snapshot.data();
            recipe.id = r.id;
            recipe.title = fullRecipe.title;
            if (fullRecipe.name != null){
              recipe.name = fullRecipe.name;
            }
            recipe.images = fullRecipe.images;
            if (fullRecipe.cookTime != null){
              recipe.cookTime = fullRecipe.cookTime;
            }
            if (fullRecipe.prepTime != null){
              recipe.prepTime = fullRecipe.prepTime;
            }            
            recipe.servings = fullRecipe.servings;
            recipe.status = fullRecipe.status;
            recipe.ingredients_count = fullRecipe.ingredients.length;
            recipe.is_free = fullRecipe.is_free;
            recipe.position = r.position;

            this.add(recipe);
            
          }else {
            console.log(`Warning: Recipe does not exist - Skipping!`);
            this.$buefy.notification.open({type: 'is-danger', message: `Warning: Recipe does not exist - Skipping!`})
          }
          
        })
        .catch((error) => {
          this.$buefy.notification.open({type: 'is-danger', message: `Error fetching recipe: ${error}`})
        });
      }

    }
  }
}
</script>
