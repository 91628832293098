<template>
  <div id="ingredients">
      <h2>Ingredients ({{ingredients.length}}): </h2>
      <ul>
        <li v-bind:key="ingredient.id" v-for="ingredient in ingredients">
          <IngredientItem :ingredient="ingredient" />
        </li>
      </ul>
  </div>
</template>

<script>
import IngredientItem from './IngredientItem';

export default {
  name: 'IngredientList',
  components: {
    IngredientItem
  },
  props: [
    "ingredients"
  ]
}
</script>

<style scoped>

#ingredients {
  text-align: left;
}
</style>
