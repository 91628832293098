<template>
  <div class="my-2 image">
    <b-field grouped >
        <b-image
            style="pointer-events: none; font-size: 0;"
            class="m-0 mr-2"
            v-if="!isNew"
            :src="image.src"
            >
        </b-image>
        <p class="control">
          <b-button v-if="isNew" type="is-primary" icon-left="plus" @click="selectImage">Add Image</b-button>
          <b-button v-else icon-right="delete" @click="deleteImage" />
        </p>
    </b-field>
    <SelectImageModal v-if="false" /> <!-- Silence warning -->
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid'
import SelectImageModal from "@/components/modals/SelectImageModal.vue"
import Storage from "@/firebase/storage"

export default {
  name: 'EditImage',
  components: {
    SelectImageModal
  },
  props: {
    value: Object,
    isNew: Boolean
  },
  computed: {
    image: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },
  },
  methods: {
    selectImage() {
      this.$buefy.modal.open({
          parent: this,
          component: SelectImageModal,
          hasModalCard: true,
          trapFocus: true,
          props: {
          },
          events: {
            'image-selected': blob => {
              this.saveImage(blob)
            }
          }
      })
    },
    saveImage(blob) {
      // TODO we need to generate a new id here

      var newImage = {};
      newImage.id = uuidv4();
      newImage.path = `images/recipes/${newImage.id}.jpg`

      console.log(newImage.path)

      let uploadTask = Storage.ref().child(newImage.path).put(blob);

      uploadTask.on('state_changed',
        (snapshot) => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log('Upload is ' + progress + '% done');
          // switch (snapshot.state) {
          //   case Storage.TaskState.PAUSED: // or 'paused'
          //     console.log('Upload is paused');
          //     break;
          //   case Storage.TaskState.RUNNING: // or 'running'
          //     console.log('Upload is running');
          //     break;
          // }
        },
        (error) => {
          // Handle unsuccessful uploads
          this.$buefy.toast.open({type: 'is-danger', message: `Error saving recipe: ${error}`})
        },
        () => {
          // Handle successful uploads on complete
          // For instance, get the download URL: https://firebasestorage.googleapis.com/...
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            console.log('File available at', downloadURL);
            newImage.src = downloadURL
            this.$emit('add-image', newImage)
          });
        }
      );
    },
    deleteImage() {

      // We notify of deleting the image this just removes the reference to the image
      // it does not delete the file stored. See below. 
      this.$emit('remove-image', this.image);

      // We've removed deleting the image files below
      // The issue arises with duplicating recipes / meal plans and then
      // deleting images from the duplicate, remove the original. 
      // The thinking is it's probably less storage to store unused
      // images than duplicating every image that are correctly used in duplicate recipes. 
      // we can plan to delete unused images through admin/manage portal periodically

      // // Create a reference to the file to delete
      // var deleteRef = Storage.ref().child(this.image.path)

      // // Delete the file
      // deleteRef.delete().then(() => {
      //   // File deleted successfully
      //   this.$emit('remove-image', this.image)
      // }).catch((error) => {
      //   // Uh-oh, an error occurred!
      //   console.log("TODO: handle error! delete failed: "+ error + " file:"+ this.image.src);
      // });
    }
  }
}
</script>

<style scoped>
</style>
