<template>
  <div class="recipe my-0 py-1">
    <b-field grouped >
      <b-icon
          v-if="!isNew"
          class="drag-handle pt-4 pr-1"
          icon="drag">
      </b-icon>
      <b-icon
          v-if="isNew"
          class="pt-4 pr-1"
          icon="plus">
      </b-icon>
      <figure v-if="!isNew" class="m-0 mx-2 p-0 image is-48x48">
        <img class="thumbnail" :src="thumbnailSrc">
      </figure>
      <b-autocomplete class="is-flex-grow-1 mr-1" custom-class="has-text-weight-bold"
            v-model="recipe.title"
            field="title"
            :disabled="!isNew"
            :data="suggestions"
            :loading="isLoading"
            placeholder="Select a recipe..."
            open-on-focus
            keep-first
            @typing="getAsyncData"
            @select="option => selected(option)">
            <template slot-scope="props">
              <div class="media p-2">
                        <div class="media-left">
                            <img width="48" :src="`${optionThumbnailSrc(props.option)}`">
                        </div>
                        <div class="media-content">
                            <h1  v-if=props.option.is_variant class="is-size-5 has-text-weight-bold pb-0 mb-0">
                              {{ props.option.title }} 
                              <span class="has-text-success">({{props.option.name}})</span>
                            </h1>
                            <h1 v-else class="is-size-5 has-text-weight-bold pb-0 mb-0">{{ props.option.title }}</h1>
                            <p class="is-size-8 mt-1">
                              <span :class="[
                                          'tag',
                                          {'is-danger is-light': props.option.status == 'draft'},
                                          {'is-warning': props.option.status == 'test'},
                                          {'is-info': props.option.status == 'fix'},
                                          {'is-success': props.option.status == 'live'}
                                        ]">
                                    <b>{{ props.option.status }}</b>
                                </span>
                                <b-tag
                                    class="mx-1"
                                    :type="props.option.is_free ? 'is-success' : 'is-white'">
                                  <b-icon 
                                    size="is-small"
                                    :type="props.option.is_free ? 'is-white' : 'is-default'"
                                    icon = "star">
                                  </b-icon>
                                </b-tag>
                              Serves: <b>{{ props.option.servings }}</b>, 
                              Ingredients: <b>{{ props.option.ingredients.length }}</b>, 
                              Prep: <b>{{ props.option.prepTime }}</b>,
                              Cook: <b>{{ props.option.cookTime }}</b>
                            </p>
                        </div>
                    </div>
            </template>
         </b-autocomplete>
         <b-field
              class="is-flex-shrink-5"
              v-if="!isNew && shouldDisplayName()"
              label="Variant name"
              label-position="on-border"
               type="is-primary">
               <b-input
                  v-model="recipe.name"
                  disabled
                  type="is-success"
                  custom-class="has-text-weight-bold">
              </b-input>
           </b-field>
           <span 
            v-if="recipe.status != null"
            :class="[
                          'tag',
                          'is-large',
                          'mr-1',
                          {'is-danger is-light ': recipe.status == 'draft'},
                          {'is-warning': recipe.status == 'test'},
                          {'is-info': recipe.status == 'fix'},
                          {'is-success': recipe.status == 'live'}
                        ]">
                    <b>{{ recipe.status }}</b>
          </span>
           
       <b-tooltip
        :label="this.recipe.is_free ? 'Free to non-members' : 'Member recipe'"
        type="is-success"
        position="is-top"
        size="is-large">
          <b-tag
            v-if="!isNew"
            size="is-large"
            class="mr-1"
            :type="this.recipe.is_free ? 'is-success' : 'is-default'">
          <b-icon 
            size="is-small"
            :type="this.recipe.is_free ? 'is-white' : 'is-default'"
            icon = "star">
          </b-icon>
        </b-tag>
          <!-- <b-button
              v-if="!isNew"
              class="mr-2"
              :type="this.recipe.is_free ? 'is-success' : 'is-default'"
              icon-right = "star"
              @click="makeFree()"
              >
          </b-button>  -->
      </b-tooltip>
      <p class="control">
        <b-button v-if="!isNew" class="mr-2" icon-right="arrow-right" @click="viewRecipe">View recipe</b-button>
        <b-button v-if="isNew" :disabled="!isValidRecipe" type="is-primary" icon-right="plus" @click="add" />
        <b-button v-else icon-right="delete" @click="remove" />
      </p>
    </b-field>
  </div>
</template>

<script>
//import db from "@/firebase/db"
import debounce from 'lodash/debounce'
import search from '../search'

export default {
  name: 'EditRecipe',
  props: {
    recipeIn: Object,
    isNew: Boolean
  },
  data() {
    return {
      recipe: null,
      query: '',
      suggestions: [],
      isLoading: false
    }
  },
  created() {
    this.recipe = this.recipeIn ?? { id: null, title: "", images: null, is_free: false };
    this.recipe.is_free = this.recipe.is_free ?? false;
    
  },
  mounted() {
    this.suggestions = this.getAsyncData("");
  },
  computed: {
    isValidRecipe() {
      return this.recipe.id != null
    },
    filteredSuggestions() {
      return this.suggestions.filter(option => {
        return option.title.toLowerCase().indexOf(this.recipe.title.toLowerCase()) >= 0
      });
    },
    thumbnailSrc() {
      if (this.recipe.images != null && this.recipe.images[0] != null) {
        return this.recipe.images[0].src;
      }
      return require("../assets/listing-placeholder.png");
    }
  },
  methods: {
      getAsyncData: debounce(function (query) {
        // String update
        if (this.query !== query) {
            this.query = query;
            this.suggestions = [];
        }
        // String cleared
        if (!query.length) {
            this.suggestions = []
            return
        }
        
        this.isLoading = true;

        let searchParameters = {
          'q'         : query,
          'preset'    : 'admin_recipe_search',
          'sort_by': '_text_match:desc',
          'per_page': '100'
        };

        // NB!!! 
        // This search is hardcoded to the live Typsense Cluster! 
        // TODO: install a local version for developing locally! 
        let that = this;
        search.collections('recipes')
          .documents()
          .search(searchParameters)
          .then(function (searchResults) {
            that.suggestions = searchResults.hits.map((hit) => hit.document );
          })
          .finally(() => {
            that.isLoading = false;
          })
      }, 500),
    selected(option) {
      if (option == null) {
        return;
      }

      this.recipe.id = option.id;
      this.recipe.title = option.title;
      this.recipe.status = option.status;
      this.recipe.is_free = option.is_free;
      
      if (option.name != null){
        this.recipe.name = option.name;
      }
      this.recipe.images = option.images;
      if (option.cookTime != null){
        this.recipe.cookTime = option.cookTime;
      }
      if (option.prepTime != null){
        this.recipe.prepTime = option.prepTime;
      }
      
      this.recipe.servings = option.servings;
      this.recipe.ingredients_count = option.ingredients.length;

      // TODO: primary tags - when we support tags! 

      setTimeout(() => this.add(), 50); // timeout here is weird but allows us time to clear the empty input field
    },
    add() {
      this.$emit('add-recipe', this.recipe);
      this.recipe = { id: null, title: "", images: null, is_free: false };
    },
    remove() {
      this.$emit('remove-recipe', this.recipe);
    },
    viewRecipe() {
      this.$router.push({ name: 'RecipeView', params: { id: this.recipe.id } })
    },
    makeFree() {
      this.$emit('make-recipe-free', this.recipe);
    },
    shouldDisplayName() {
      return (this.recipe.name != null) && (this.recipe.name != "");
    },
    customOptionTitle(option) {
      let internalNameString = (option.name != null) && (option.name != "") ? ` (${option.name})`: "";
      return `${option.title}${internalNameString}`;
    },
    optionThumbnailSrc(option) {
      let image = option.images[0];
      if (image != null) {
        return image.src;
      }
      return null;
    }
  }
}
</script>

<style>
.thumbnail{
  height: 100% !important;
  object-fit: cover;
}
</style>
